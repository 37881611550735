import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import BlogPostPublishDate from './BlogPostPublishDate';
import Link from './Link';
import PictureSrcSet from './PictureSrcSet';

const useStyles = makeStyles(theme => ({
	container: {
		margin: `${theme.sizes.gutter}px 0`,
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			margin: `${theme.sizes.componentSpacing / 2}px 0`,
		},
		[theme.breakpoints.up('md')]: {
			margin: `${theme.sizes.componentSpacing}px 0`,
		},
	},
	introText: {
		margin: `${theme.spacing(2)}px auto`,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 600,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 750,
		},
	},
	publishDate: {
		display: 'block',
		fontSize: 14,
		fontWeight: 700,
		margin: theme.spacing(4, 0, 2),
		textTransform: 'uppercase',
	},
	title: {
		fontSize: 36,
		margin: theme.spacing(2, 0),
	},
}));

const FeaturedBlogPost = ({
	post: {
		content: { hero, introText, publishDate },
		url,
	},
}) => {
	const classes = useStyles();
	const breakpoints = [
		{
			breakpoint: 'md',
			height: 480,
			smartCrop: true,
			width: 960,
		},
		{
			breakpoint: 'sm',
			height: 340,
			smartCrop: true,
			width: 680,
		},
		{
			breakpoint: 'xs',
			height: 175,
			smartCrop: true,
			width: 350,
		},
	];

	return (
		<section className={classes.container}>
			<Link to={`/${url}`}>
				<PictureSrcSet
					originalUrl={hero[0].backgroundImage}
					altText={hero[0].headline}
					breakpoints={breakpoints}
				/>
			</Link>
			<Typography component="h2" className={classes.title}>
				<Link to={`/${url}`}>{hero[0].headline}</Link>
			</Typography>
			<Typography className={classes.introText}>{introText}</Typography>
			<BlogPostPublishDate
				publishDate={publishDate}
				className={classes.publishDate}
			/>
		</section>
	);
};

export default FeaturedBlogPost;
