import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';

import BlogPostCard from 'components/BlogPostCard';
import Container from 'components/Container';
import FeaturedBlogPost from 'components/FeaturedBlogPost';
import PageWrapper from 'components/PageWrapper';

import Hero from 'storyblok/components/Hero';

const blog = ({ data }) => {
	const [latestPost, ...remainingPosts] = data.blogPosts.edges.map(
		({ node: { content, full_slug } }) => ({
			content: JSON.parse(content),
			url: full_slug,
		})
	);

	return (
		<PageWrapper
			title="Blog"
			description={`The latest ${data.site.siteMetadata.title} news.`}
			ogImage={{
				alt: data.site.siteMetadata.title,
				src: `${data.site.siteMetadata.url}${data.headerImage.childImageSharp.fixed.src}`,
			}}
			postContent={
				<>
					<Hero
						blok={{
							backgroundImage:
								data.headerImage.childImageSharp.fluid,
							headline: 'The Blog',
							height: 'short',
						}}
					/>
					<Container>
						<FeaturedBlogPost post={latestPost} />
						<Grid
							container
							spacing={2}
							justify="center"
							component="section"
						>
							{remainingPosts &&
								remainingPosts.map(post => (
									<Grid
										key={post.url}
										item
										xs={12}
										sm={6}
										md={4}
									>
										<BlogPostCard post={post} />
									</Grid>
								))}
						</Grid>
					</Container>
				</>
			}
		/>
	);
};

export const query = graphql`
	query GetBlogListingContent {
		headerImage: file(relativePath: { eq: "blog-hero.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1500) {
					...GatsbyImageSharpFluid_tracedSVG
				}
				fixed(height: 630, width: 1200) {
					src
				}
			}
		}
		blogPosts: allStoryblokEntry(
			filter: { field_component: { eq: "BlogPost" } }
			sort: { fields: field_publishDate_string, order: DESC }
		) {
			edges {
				node {
					full_slug
					content
				}
			}
		}
		site {
			siteMetadata {
				title
				url
			}
		}
	}
`;

export default blog;
